import { defineComponent, reactive, onMounted } from 'vue';
import { getUserInfo, updateKey } from '@/api/user/user';
import { setSignUpInfo } from '@/utils/auth';
import routerJump from '@/utils/router';
export default defineComponent({
  name: 'ProfileCpt',

  setup() {
    onMounted(() => {
      getUserInfoFn();
    }); // 获取个人信息相关

    const getUserInfoFn = () => {
      getUserInfo().then(res => {
        if (res.statusCode === 200) {
          profileModule.userInfo = res.data;
        }
      });
    }; // 刷新key


    const updateKeyFn = () => {
      updateKey().then(res => {
        if (res.statusCode === 200) {
          profileModule.userInfo.key = res.data.key;
        }
      });
    }; // 去验证邮箱


    const verifyEmail = () => {
      setSignUpInfo(profileModule.userInfo.email, profileModule.userInfo.id);
      routerJump('/activate/mail');
    };

    let profileModule = reactive({
      userInfo: {
        key: '',
        status: 0,
        email: '',
        id: ''
      },
      updateKeyFn,
      verifyEmail
    });
    return profileModule;
  }

});