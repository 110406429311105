const getLang = (lang?: string) => {
    // return 'en';
    if (lang) return lang
    switch (document.domain) {
        case "gobies.org":
        case "www.gobies.org":
        case "10.10.10.161":
            return "en"
        case "www.gobysec.net":
        case "cn.gobies.org":
        case "gobysec.net":
        case "localhost":
        case "goby.fofa.info":
        case "10.10.10.160":
            return "zh"
      default:
        return 'zh'
    }
}

export {
    getLang
}
