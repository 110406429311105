import { defineComponent, reactive, onUnmounted, onMounted } from 'vue';
import downGoby from "@/utils/downGoby";
import { getLang } from '@/utils/lang';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: "EditionView",
  props: {
    title: {
      type: String,
      default: ""
    },
    desc: {
      type: String,
      default: ""
    },
    feature: {
      type: Array,
      default: () => []
    },
    downData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    keys: {
      type: Number
    }
  },
  emits: ["change"],

  setup(props, ctx) {
    let {
      show,
      down
    } = downGoby();
    const router = useRouter();

    const clickEvent = function () {
      setupData.show = false;
    };

    document.body.addEventListener('click', clickEvent, false);
    onUnmounted(() => {
      document.body.removeEventListener('click', clickEvent);
    });
    onMounted(() => {
      window.scrollTo(0, 0);
    });

    const setShow = function (keys) {
      setupData.show = !setupData.show;
      ctx.emit('change', keys);
    };

    const chat = function (edition) {
      router.push({
        name: "chat",
        params: {
          edition
        }
      });
    };

    let setupData = reactive({
      down,
      show,
      setShow,
      lang: getLang(),
      chat
    });
    return setupData;
  }

});